import { createStore } from 'vuex'
import MipresService from '../services/listMipres'

const _MipresService = new MipresService()

const storeIncomes = {
  state: {
    paginator: {
      rows: 20,
      first: 0
    },
    mipres: {
      count: 0,
      rows: []
    },
    mipresPrescrip: {
      count: 0,
      rows: []
    },
    EntregasFact: {
      count: 0,
      rows: []
    },
    reportesFact: {
      count: 0,
      rows: []
    },
    reportesFactList: {
      count: 0,
      rows: []
    },
    mipresMasivo: {
      count: 0,
      rows: []
    },
    mipresHist: {
      count: 0,
      rows: []
    },
    accionMipres: {},
    respuesta: [],
    actualizarMipres: {},
    token: null,
    difference: 0
  },
  mutations: {
    setPaginator (state, payload) {
      state.paginator = {
        ...payload
      }
    },
    settoken (state, payload) {
      state.token = {
        ...payload
      }
    },
    setmipres (state, payload) {
      state.mipres = {
        ...payload
      }
    },
    setrespuesta (state, payload) {
      state.respuesta = payload
    },
    setentregasfact (state, payload) {
      state.EntregasFact = {
        ...payload
      }
    },
    setmipresMasivo (state, payload) {
      state.mipresMasivo = {
        ...payload
      }
    },
    setmipresHist (state, payload) {
      state.mipresHist = {
        ...payload
      }
    },
    setReporteFact (state, payload) {
      state.reportesFact = {
        ...payload
      }
    },
    setReporteFactList (state, payload) {
      state.reportesFactList = {
        ...payload
      }
    },
    setAccionMipres (state, payload) {
      state.accionMipres = {
        ...payload
      }
      state.respuesta = payload || ''
    },
    setActualizarMipres (state, payload) {
      state.accionMipres = {
        ...payload
      }
    },
    setmipresPres (state, payload) {
      state.mipresPrescrip = {
        ...payload
      }
    }
  },
  actions: {

    getToken ({ commit }, payload) {
      _MipresService.getToken(payload).then(({ data }) => {
        commit('settoken', data[0])
      }).catch(error => {
        console.log(error)
      })
    },

    updateToken ({ commit }, payload) {
      _MipresService.updateToken(payload).then(({ data }) => {
        commit('settoken', data)
      }).catch(error => {
        console.log(error)
      })
    },

    searchMipres ({ commit }, payload) {
      _MipresService.getMipres(payload).then(({ data }) => {
        commit('setmipres', data)
      }).catch(error => {
        console.log(error)
      })
    },

    consultarMipres ({ commit }, payload) {
      _MipresService.getMipresPrescrip(payload).then(({ data }) => {
        console.log('data de miprs', data)
        commit('setmipresPres', data)
      }).catch(error => {
        console.log(error)
      })
    },

    searchEntregaFact ({ commit }, payload) {
      _MipresService.getEntregasFact(payload).then(({ data }) => {
        commit('setentregasfact', data)
      }).catch(error => {
        console.log(error)
      })
    },

    searchMipresMasivo ({ commit }, payload) {
      _MipresService.getMipres(payload).then(({ data }) => {
        commit('setmipresMasivo', data)
      }).catch(error => {
        console.log(error)
      })
    },

    searchReportesFac ({ commit }, payload) {
      _MipresService.getReportesFac(payload).then(({ data }) => {
        commit('setReportesFac', data)
      }).catch(error => {
        console.log(error)
      })
    },

    searchMipresHist ({ commit }, payload) {
      _MipresService.getMipresHist(payload).then(({ data }) => {
        commit('setmipresHist', data)
      }).catch(error => {
        console.log(error)
      })
    },

    searchReportesFact ({ commit }, payload) {
      _MipresService.getReportesFact(payload).then(({ data }) => {
        commit('setReporteFact', data)
      }).catch(error => {
        console.log(error)
      })
    },
    searchReportesFactList ({ commit }, payload) {
      _MipresService.getReportesFactList(payload).then(({ data }) => {
        commit('setReporteFactList', data)
      }).catch(error => {
        console.log(error)
      })
    },

    accionMipres ({ commit }, payload) {
      _MipresService.accionMipres(payload).then(({ data }) => {
        commit('setAccionMipres', data)
      }).catch(error => {
        console.log(error)
      })
    },

    actualizarMipres ({ commit }, payload) {
      _MipresService.actualizarMipres(payload).then(({ data }) => {
        commit('setActualizarMipres', data)
      }).catch(error => {
        console.log(error)
      })
    },

    gettingPaginator ({ commit }, payload) {
      commit('setPaginator', payload)
    },

    getTemplate ({ commit }, payload) {
      _MipresService.getMipres(payload).then(({ data }) => {
      }).catch(error => {
        console.log(error)
      })
    },

    descargarEntregas ({ commit }, payload) {
      _MipresService.getEntregasMasivas(payload).then(({ data }) => {
        commit('setrespuesta', data)
      }).catch(error => {
        console.log(error)
      })
    },

    setRespuesta ({ commit }, payload) {
      commit('setrespuesta', payload)
    }

  },
  getters: {
    getToken: state => state.token,
    getMipres: state => state.mipres,
    getMipresPrescrip: state => state.mipresPrescrip,
    getEntregasFact: state => state.EntregasFact,
    getMipresMasivo: state => state.mipresMasivo,
    getMipresHist: state => state.mipresHist,
    getReportesFacturacion: state => state.reportesFact,
    getReportesFacturacionList: state => state.reportesFactList,
    getPaginator: state => state.paginator,
    getRespuesta: state => state.respuesta
  }
}
const store = createStore(storeIncomes)

export default {
  namespaced: true,
  ...store
}
