import http from '../../../../../libs/http'
import axios from 'axios'
// import http from '../../../../../libs/https/http.js'

// const api = `${process.env.VITE_MICROSERVICE_API_MIPRES}`
const api = process.env.VUE_APP_MICROSERVICE_API_MIPRES

export default class MipresService {
    getMipres (query = {}) {
      return http.get(`${api}/mipres`, {
        params: {
          ...query
        },
        headers: {
          loading: true
        }
      })
    }

    getMipresHist (query = {}) {
      return http.get(`${api}/mipres-historial`, {
        params: {
          id: query
        },
        headers: {
          loading: true
        }
      })
    }

    getEntregasFact (query = {}) {
      return http.get(`${api}/entregas-fact`, {
        params: {
          ...query
        },
        headers: {
          loading: true
        }
      })
    }

    getReportesFact (query = {}) {
      return http.get(`${api}/reportes-facturacion`, {
        params: {
          ...query
        },
        headers: {
          loading: true
        }
      })
    }

    getReportesFactList (query = {}) {
      return http.get(`${api}/reportes-facturacion-listado`, {
        params: {
          ...query
        },
        headers: {
          loading: true
        }
      })
    }

    getToken (query = {}) {
      return http.get(`${api}/token-mipres`, query, {
        headers: {
          loading: true
        }
      })
    }

    updateToken (query = {}) {
      return http.put(`${api}/update-mipres`, query, {
        params: {
          ...query
        },
        headers: {
          loading: true
        }
      })
    }

    getTemplate (params) {
      const url = `${api}/export-template-xls`
      const res = axios.getUri({ url, params })
      //       console.log(res)
      window.open(res, '_blank')
    }

    accionMipres (query = {}) {
      return http.post(`${api}/accion-mipres`, query.datos, {
        params: {
          tipo: query.tipo
        },
        headers: {
          loading: true
        }
      })
    }

    actualizarMipres (query = {}) {
      return http.post(`${api}/actualizar-mipres`, query, {
        params: {
          ...query
        },
        headers: {
          loading: true
        }
      })
    }

    getMipresPrescrip (nPres) {
      return http.get(`${api}/consultar-mipres`, {
        params: {
          nPres
        },
        headers: {
          loading: true
        }
      })
    }

    getEntregasMasivas (query = {}) {
      return http.get(`${api}/entregas-masivas`, {
        params: {
          ...query
        },
        headers: {
          loading: true
        }
      })
    }

    getClientes () {
      return http.get(`${api}/listar-clientes`, {
        headers: {
          loading: true
        }
      })
    }

    programarMasivo (data) {
      return http.post(`${api}/accion-mipres`, data, {
        params: {
          tipo: 'Programacion'
        },
        headers: {
          loading: true
        }
      })
    }
}
