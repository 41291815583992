<template>
    <div>
        <Toast />
        <div class="accordion-item my-4">
            <div
                id="faq-accordion-collapse-1"
                class="accordion-collapse collapse show mt-2"
                aria-labelledby="faq-accordion-content-1"
                data-bs-parent="#faq-accordion-1"
            >
                <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed p-4">
                <div class="grid grid-cols-9 gap-x-6 gap-y-2">
                    <div class="col-span-3 md:col-span-6 lg:col-span-4 sm:col-span-7">
                        <label class="block text-gray-700">Token Mipres:</label>
                        <InputText class="w-full" type="text" v-model="token.value" />
                    </div>

                    <button
                    @click="updateToken(token)"
                    type="button"
                    class="p-button-sm bg-primary-1 p-2  mt-auto text-white rounded-md sm:col-span-3 md:col-span-2 lg:col-span-1"
                    >
                        Actualizar
                    </button>

                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, onMounted, watch, ref } from 'vue'
import storeMipres from './stores/storeMipres'
import { error, confirm } from '../../../../libs/mensajes'
import { useToast } from 'primevue/usetoast'
export default {

    setup () {
        const toast = useToast()
        const token = ref({
            key: '',
            value: ''
        })
        const storeToken = computed(() => storeMipres.getters.getToken)

        const updateToken = () => {
            confirm(
                '',
                'Token',
                'warning',
                `¿Esta seguro de cambiar el token <strong>${storeToken.value.value}</strong> ?, esta acción es irreversible!`
            ).then(({ isConfirmed }) => {
                if (isConfirmed) {
                storeMipres.dispatch('updateToken', token.value).then(() => {
                        toast.add({ severity: 'success', summary: 'Exito', detail: 'Token actualizado correctamente', life: 3000 })
                    }).catch((err) => {
                    console.error(err.message)
                    error(err.response.data.message)
                })
                } else {
                    console.log('no confirmo', isConfirmed)
                }
            })
        }

        onMounted(() => {
            storeMipres.dispatch('getToken')
        })

        watch(() => storeToken.value, (a) => {
            token.value = storeToken.value
        })

        return {
            token,
            storeToken,
            updateToken
            // can
        }
    }
}
</script>
<style lang="">

</style>
